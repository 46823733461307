import { StaticCellId } from "@hex/common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";

interface NotificationModalState {
  isOpen: boolean;
  staticCellId: StaticCellId | null;
}

type AppViewSliceState = {
  notificationModal: NotificationModalState;
  hasSchedules: boolean;
};

const initialAppViewState: AppViewSliceState = {
  notificationModal: {
    isOpen: false,
    staticCellId: null,
  },
  hasSchedules: false,
};

const appViewSlice = createSlice({
  name: "appView",
  initialState: initialAppViewState,
  reducers: {
    openNotificationModalForCell: (
      state,
      action: PayloadAction<StaticCellId>,
    ) => {
      state.notificationModal.isOpen = true;
      state.notificationModal.staticCellId = action.payload;
    },
    openNotificationModal: (state) => {
      state.notificationModal.isOpen = true;
    },
    closeNotificationModal(state) {
      state.notificationModal.isOpen = false;
      state.notificationModal.staticCellId = null;
    },
    setHasSchedules: (state, action: PayloadAction<boolean>) => {
      state.hasSchedules = action.payload;
    },
  },
});

export const selectNotificationModalState = (
  state: RootState,
): NotificationModalState => state.appView.notificationModal;

export const selectHasSchedules = (state: RootState): boolean =>
  state.appView.hasSchedules;

export const appViewReducer = appViewSlice.reducer;
export const {
  closeNotificationModal,
  openNotificationModal,
  openNotificationModalForCell,
  setHasSchedules,
} = appViewSlice.actions;
